var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"90%","icon":"mdi-cellphone-dock","color":"primary","title":"Agentes y Teléfonos","text":"Tabla resumen de telefonos y agentes (Con credenciales)"}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.telefonos,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Filtrar por nombre: "+_vm._s(_vm.search))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","append-icon":"search","placeholder":"Escribe el teléfono","single-line":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Agregar teléfono")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-update")]),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])],1),_c('v-divider'),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-container',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Número")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Número de teléfono 56...."},model:{value:(_vm.editedItem.numero),callback:function ($$v) {_vm.$set(_vm.editedItem, "numero", $$v)},expression:"editedItem.numero"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Google Contact Email ")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Correo de Google Contact"},model:{value:(_vm.editedItem.googleContactEmail),callback:function ($$v) {_vm.$set(_vm.editedItem, "googleContactEmail", $$v)},expression:"editedItem.googleContactEmail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Agentes")]),_c('v-select',{attrs:{"dense":"","hide-details":"","placeholder":"Selecciona un agente","outlined":"","items":_vm.agentes,"item-value":"_id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nombre)+" "+_vm._s(item.apellido))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nombre)+" "+_vm._s(item.apellido))])]}}],null,true),model:{value:(_vm.editedItem.agenteId),callback:function ($$v) {_vm.$set(_vm.editedItem, "agenteId", $$v)},expression:"editedItem.agenteId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold ma-0"},[_vm._v(" Telegram ID ")]),_c('VTextFieldWithValidation',{attrs:{"rules":"","label":"ID de Telegram"},model:{value:(_vm.editedItem.telegramId),callback:function ($$v) {_vm.$set(_vm.editedItem, "telegramId", $$v)},expression:"editedItem.telegramId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Client ID")]),_c('VTextFieldWithValidation',{attrs:{"rules":"","label":"ID del cliente de Google Cloud"},model:{value:(_vm.editedItem.credenciales.clientId),callback:function ($$v) {_vm.$set(_vm.editedItem.credenciales, "clientId", $$v)},expression:"editedItem.credenciales.clientId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Client Secret ")]),_c('VTextFieldWithValidation',{attrs:{"rules":"","label":"ID secreto del cliente"},model:{value:(_vm.editedItem.credenciales.clientSecret),callback:function ($$v) {_vm.$set(_vm.editedItem.credenciales, "clientSecret", $$v)},expression:"editedItem.credenciales.clientSecret"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.$router.push({
                                  name: 'Telefonos-googleContact',
                                  params: {
                                    id: _vm.editedItem._id,
                                    clientId:
                                      _vm.editedItem.credenciales.clientId,
                                    clientSecret:
                                      _vm.editedItem.credenciales.clientSecret,
                                  },
                                })}}},[_vm._v("Generar credenciales")])],1),_c('v-col',[_c('v-btn',{attrs:{"color":"#251B4A","dark":""},on:{"click":function($event){_vm.$router.push({
                                  name: 'Telefonos-contactos',
                                  params: { id: _vm.editedItem._id },
                                });
                                _vm.$store.commit(
                                  'setSelectedTelefono',
                                  _vm.editedItem
                                );}}},[_vm._v("Exportar contactos")])],1)],1)],1),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"loading":_vm.loadingButton,"color":"success"},on:{"click":function($event){return passes(_vm.save)}}},[_vm._v("Guardar")])],1)]}}])})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
                                var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],staticClass:"mr-3 mb-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Editar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("Eliminar")])]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v("Aún no cuentas con telefonos")])]},proxy:true},{key:"item.agenteId",fn:function(ref){
                                var item = ref.item;
return [_vm._v(_vm._s(item.agenteId ? item.agenteId.nombre : "")+" "+_vm._s(item.agenteId ? item.agenteId.apellido : ""))]}},{key:"item.createdAt",fn:function(ref){
                                var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))]}},{key:"item.active",fn:function(ref){
                                var item = ref.item;
return [_c('v-switch',{staticStyle:{"width":"20px"},on:{"change":function($event){return _vm.updateActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.status",fn:function(ref){
                                var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Inactivo")])]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.telefonos.length ? _vm.telefonos.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.telefonos.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }